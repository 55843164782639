import React, { useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Industries from "../components/Repeating/Industries";
import Testimonials from "../components/Repeating/Testimonials";
import FAQs from "../components/Repeating/FAQs";
import Subscribe from "../components/Repeating/Subscribe";
import RecentPosts from "../components/Repeating/RecentPosts";
import CallToAction from "../components/Repeating/CTA";
import ModalTeamMembers from "../components/Modal/ModalTeamMembers";
import ButtonSolid from "../components/Button/ButtonSolid";
import { mapEdgesToNodes } from "../lib/helpers";

const Page = ({ data }) => {
  

  const teamMembersNodes = (data || {}).teamMembers
    ? mapEdgesToNodes(data.teamMembers)
    : [];

  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout>
      <SearchEngineOptimization
        title="About Us | Cannabis Accountants | Dark Horse Cannabis CPA"
        description="We outshine the competition by design. Learn how we've reimagined and disrupted everything you know about the accounting industry. Read more about us here!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Weed Go Well Together</h1>
              <p>
                Your business needs a partner who understands you and the industry in which you operate. At Dark Horse Cannabis CPAs, we fundamentally believe in and care about the success of the cannabis industry, and are making it our mission to ensure your business is set up to thrive.
              </p>
              <ButtonSolid href="/pricing-tool/" text="Get a Quote" />
            </div>
            <div className="relative order-1 md:order-2">
              <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-2 md:mb-32 md:pt-16">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-2">
              <h1>Teamwork Makes the Dream Work</h1>
              <p>
              At Dark Horse Cannabis CPAs, we believe a collaborative environment produces the best outcome for your business. That's why we've built a team that assists each other to ensure all portions of your business get the attention they deserve.
              </p>
              
            </div>
            <div className="relative order-1 md:order-1">
              <GatsbyImage image={data.intro.childImageSharp.gatsbyImageData} />
              
            </div>
          </div>
        </div>
      </section>

      

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-16 text-left">
            <h2>Meet the Cannabis CPA Team</h2>
            <p className="max-w-[728px]">When you work with Dark Horse, you work with people. Get to know our people below.</p>
          </header>
          {teamMembersNodes.length > 0 && (
            <div className="grid grid-cols-2 gap-y-6 gap-x-6 md:grid-cols-3 lg:gap-x-10">
              {teamMembersNodes.map((item, i) => (
                <div key={i}>
                  <button
                    data-modal-open="modal-team-members"
                    onClick={() => setSlideIndex(i)}
                    className="group text-left font-normal no-underline"
                  >
                    <div className="mb-2.5 overflow-hidden rounded-4xl md:mb-3.5">
                      <GatsbyImage
                        image={item.headshot.asset.gatsbyImageData}
                        className="over w-full scale-100 transform blur-none filter transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div className="text-lg font-bold text-black md:text-xl">
                      {item.name}
                    </div>
                    <div className="mb-8 text-sm font-bold uppercase tracking-wide text-primary-900/40">
                      {item.title}
                    </div>
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      
     
     
      <CallToAction />
      <ModalTeamMembers slideIndex={slideIndex} slides={teamMembersNodes} />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Fractional CFO.jpg" }
    ) {
      publicURL
    }
    horseHead: file(relativePath: { eq: "global/logo-head-black.svg" }) {
      publicURL
    }
    horseHeadOrange: file(relativePath: { eq: "global/logo-head-orange.svg" }) {
      publicURL
    }
    rotatingWords: file(
      relativePath: { eq: "global/rotating-words-cfo-black.svg" }
    ) {
      publicURL
    }
    hero: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/1.0 About Hero.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
    intro: file(
        relativePath: { eq: "Business Solutions/Fractional CFO/2.0 Intro.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 560)
        }
      }
    dashboard: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/dashboard.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    reports: file(
      relativePath: { eq: "Business Solutions/Fractional CFO/reports.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1120)
      }
    }
    teamMembers: allSanityTeamMember(
        filter: {industries: {elemMatch: {}}, industriesFilter: {elemMatch: {title: {eq: "Cannabis"}}}}
      ) {
        edges {
          node {
            id
            name
            headshot {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 564)
                title
              }
            }
            directPhoneNumber
            emailAddress
            linkedinProfile
            hubspotID
            _rawBiography
            title
          }
        }
      }
  }
`;

export default Page;
